.section {
  /* background: url("https://applicants.testassessify.com/static/media/bg2.9276b7e591637e137a3a.jpg"); */
  background: url("../../assets/images/authBG.jpg") repeat;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-color: gainsboro; */
}
.container {
  /* background-color: #f6f8f9; */
}
@media screen and (min-width: 768px) {
  .container {
    display: flex !important;
    justify-content: center;
  }
  .content {
    max-width: 500px;
    width: 100%;
  }
}
.link {
  color: #000;

  text-decoration: underline;
  font-weight: 700;
}
