.leaderboardScreen{
    text-align: center;
}
.winner{
    width: 7.5rem;
    height: 7.5rem;
    /* border: 1px solid #7133BA; */
    /* border: 1px solid #E88105; */
    border-radius: 100%;
    margin:0 15px 0 15px;
    text-align: center;
    padding: 0.3rem;
    /* margin-top: -1rem; */
}
.imgCtn{
    width: 6rem;
    height: 6rem;
    /* border: 1px solid #7133BA; */
    /* border: 1px solid #E88105; */
    border-radius: 100%;
    text-align: center;
    padding: 0.3rem;
    margin:0 15px 0 15px;

    
   
    
}
.winnersSection{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--grey-color);
  
}
.position,
.winnersName{
    font-weight: 900;
    font-size: 0.8rem;
    text-align: center;

}
.winnersAvatar{
    border-radius: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 90%; */
    object-fit: contain;
    
}
.selectCtn{
    width: 20rem;
    /* border: 1px solid #7133BA; */
    border: 1px solid #E88105;
    border-radius: 6px;
    margin-bottom: 1.7rem;
    height: 40px;
}
.leaderboardCtn{
    /* w-full text-center flex flex-col justify-center items-center mt-10 text-white */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem ;
    color: white;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-size: 2px;
}
.leaderboardBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 48px;
    border-radius: 15px;
    margin-top: 20px;
    /* background-color: #7133BA; */
    /* background-color: #E88105; */
    font-size: 14px;
    /* text-[0.7rem] md:text-[14px] text-base flex justify-between items-center text-center w-[100%] h-[48px] rounded-[15px] mt-[20px] bg-primaryPurple */
}

@media screen and (max-width: 600px) {
    .leaderboardBox{
        font-size: 12.5px;
    }
   
    
  }
