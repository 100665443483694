.sidebar {
  display: none;
}
.link {
  color: white;
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .childrenContent {
    /* padding-top: 130px; */
  }
}
@media screen and (min-width: 768px) {
  .container {
    display: flex;
    /* justify-content: center; */
    width: 100%;
  }
  .sidebar {
    display: block;
    min-height: 100vh;
    background-color: #f8f8f8;
    color: #7f7f7f;
    flex-basis: 345px;
    flex: 0.3;
    min-width: 300px;
    max-width: 300px;
  }
  .childrenContainer {
    max-width: calc(100vw - 300px);
    background: url("../../assets/images/homeBG.jpg") no-repeat;
    background-size: cover;
    /* background-position: center; */
    background-color: #f0f0f0;
  }

  .childrenContent {
    display: flex;
    justify-content: center;
  }
  .content {
    width: 100%;
    max-width: 650px;
  }
  .navFlex {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .activeNavLink {
    color: rgb(113 51 186) !important;
    text-decoration: none !important;
  }
  .activeNav {
    background-color: rgba(113, 51, 186, 0.05);
  }

  .idleNavLink {
    color: #7f7f7f;
  }
}
