@tailwind base;
@tailwind components;
@tailwind utilities;



@layer components {
  .heading {
    @apply text-xl sm:text-2xl lg:text-header text-[#202124]  font-bold mb-3 md:mb-6;
  }
  .heading-2 {
    @apply text-black font-bold md:text-4xl lg:text-5xl;
  }
  .heading-para {
    @apply text-sm sm:text-base lg:text-xl 2xl:text-2xl  font-normal;
  }
  .h-heading-para {
    @apply text-sm sm:text-base lg:text-2xl;
  }
  .sub-heading {
    @apply text-sm md:text-base lg:text-xl;
  }
  .access-1 {
    @apply bg-[url('./assets/svgs/background/access-bg.svg')] bg-cover;
  }
  .corporate-1 {
    @apply bg-[url('./assets/svgs/background/corporate1.svg')] bg-cover;
  }

  .corporate-2 {
    @apply bg-[url('./assets/svgs/background/corporate2.svg')] bg-cover;
  }

  .corporate-3 {
    @apply bg-[url('./assets/svgs/background/corporate3.svg')] bg-cover;
  }
  .competition-1 {
    @apply bg-[url('./assets/images/Group592.png')] bg-cover;
  }
  .competition-2 {
    @apply bg-[url('./assets/images/Group593.png')] bg-cover;
  }
  .event-1 {
    @apply bg-[url('./assets/svgs/background/Group5731.svg')] bg-cover;
  }
  .event-2 {
    @apply bg-[url('./assets/images/Group572.png')] bg-cover;
  }
  .biblechallenge {
    /* @apply bg-[url('./assets/svgs/background/bgImage.svg')] bg-cover; */
    /* @apply bg-[url('./assets/svgs/background/xmanBG.png')] bg-cover; */
  }

  .bg-triangle {
    @apply bg-[url('./assets/svgs/background/Group7.png')] bg-cover;
  }
  .bg-level {
    @apply bg-[url('./assets/svgs/background/Path478.svg')] bg-cover bg-center ;
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
